import Vue from "vue";
import VueRouter from "vue-router";

// 页面加载进度条
import NProgress from "nprogress";
import "nprogress/nprogress.css";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "MainLayout",
    component: () =>
      import(/* webpackChunkName: "mainLayout" */ "../views/MainLayout.vue"),
    children: [
      {
        path: "/",
        name: "Home",
        component: () =>
          import(/* webpackChunkName: "mainLayout" */ "../views/Home.vue")
      },
      {
        path: "/Supply",
        name: "Supply",
        component: () =>
          import(/* webpackChunkName: "mainLayout" */ "../views/Supply.vue")
      },
      {
        path: "/Crm",
        name: "Crm",
        component: () =>
          import(/* webpackChunkName: "mainLayout" */ "../views/Crm.vue")
      },
      {
        path: "/Amazon",
        name: "Amazon",
        component: () =>
          import(/* webpackChunkName: "mainLayout" */ "../views/Amazon.vue")
      },
      {
        path: "/about",
        name: "About",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/About.vue")
      },
      {
        path: "/solution",
        name: "Solution",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Solution.vue")
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// 路由守卫
// eslint-disable-next-line no-unused-vars
router.beforeEach((to, from, next) => {
  NProgress.start();
  next();
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
